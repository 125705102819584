@import "~@config/styles.less";
.to-global-partner-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .head-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 0;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .icon {
      width: 44px;
      margin-left: 18px;
    }
    .text-container {
      flex: 1;
      margin-left: 14px;

      .identity {
        font-size: 22px;
        font-weight: 600;
        color: #1b1b1b;
      }
      .time {
        font-size: 12px;
        font-weight: 400;
        color: #9FA0A0;
        margin-top: 5px;
      }
    }
    .rules-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 14px;

      .text {
        font-size: 13px;
        font-weight: 400;
        color: #FF7801;
      }
      .icon {
        width: 14px;
        margin-left: 2px;
      }
    }
  }
  .block {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    
    .title-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;

      .title_l {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .title {
          font-size: 16px;
          font-weight: 500;
          color: #1B1B1B;
          margin-left: 16px;
        }
        .icon {
          width: 14px;
          margin-left: 6px;
        }
      }
      .select-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 16px;

        .select-name {
          font-size: 12px;
          font-weight: 400;
          color: #1B1B1B;
        }
        .select-icon {
          width: 16px;
          margin-left: 2px;
        }
      }
    }
    .menu-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 16px;
      margin-bottom: 0;

      .menu-item {
        padding: 9px 12px;
        border-radius: 32px;
        font-size: 12px;
        font-weight: 400;
        color: #9FA0A0;
        border: 1px solid #E5E5E5;
        margin-right: 6px;
      }
      .selected {
        border-color: #FF7700;
        background-color: #FF78011A;
        color: #FF7700;
      }
    }
    .content-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 15px;

      .content-l {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        margin-left: 16px;

        .content-number {
          font-size: 28px;
          line-height: 28px;
          font-weight: 500;
          color: #FF7801;
        }
        .content-symbol {
          font-size: 12px;
          font-weight: 500;
          color: #FF7801;
          line-height: 18px;
          margin-left: 5px;
        }
      }
      .content-r {
        font-size: 12px;
        font-weight: 400;
        color: #9FA0A0;
        margin-right: 16px;
      }
      .content-push-icon {
        width: 24px;
        margin-right: 16px;
      }
    }
    .process-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;

      .process {
        flex: 1;
        margin-left: 16px;
        --track-width: 4px;
        --track-color: #E6E6E6;
        --fill-color: #FF7801;
      }
      .icon {
        width: 16px;
        margin-left: 8px;
        margin-right: 16px;
      }
    }
    .margin-bottom-div {
      margin-bottom: 28px;
    }
    .line {
      width: calc(100% - 32px);
      margin: 12px 16px;
      margin-bottom: 0;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .invite-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 66px;
    background-color: white;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
      width: calc(100% - 108px);
      height: 50px;
      border-radius: 25px;
      background-color: #FF7700;
      box-shadow: 0px 3px 6px 1px rgba(255, 171, 97, 0.5);
      font-size: 16px;
      font-weight: 400;
      color: white;
      border-style: none;

      &::before {
        border-radius: 25px;
      }
    }
  }
  .mask-container {
    .content-container {
      width: calc(100% - 64px);
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .text {
        font-size: 14px;
        font-weight: 400;
        color: #898989;
        line-height: 21px;
        margin: 24px;
        text-align: center;

        .bold {
          font-weight: bold;
          color: #1B1B1B;
          display: inline-block;
        }
      }
      .confirm-button {
        width: 100%;
        height: 50px;
        --background-color: transparent;
        --text-color: #FF7700;
        --border-radius: 0;
        --border-style: none;
        border-style: none;
        border-top: 1px solid #E5E5E5;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  
}
.popup-container {
  .select-container {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    .select-menu {
      width: calc(100% - 32px);
      height: 44px;
      color: #1B1B1B;
      font-size: 14px;
      font-weight: 400;
      border-radius: 0;
    }
    .selected {
      color: #FF7801;
    }
  }
}