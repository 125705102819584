@import "~@config/styles.less";

.invitePage {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .headImage {
    width: 100%;
  }
  .body {
    padding: 0 32px 44px 32px;

    .title {
      font-size: 28px;
      font-weight: 600;
      line-height: 28px;
      color: @color_1b1b1b;
      margin-top: 24px;
      text-align: center;
    }
    .content {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: @color_898989;
      margin-top: 20px;
      text-align: center;
    }
    .code {
      margin-top: 24px;
      width: 100%;
      height: 56px;
      background-color: @color_fffbf7;
      border: 1px dashed @color_ffbb80;
      border-radius: 28px;
      font-size: 24px;
      font-weight: 600;
      line-height: 25px;
      color: @color_ff9602;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 6px;
      text-indent: 10px;
      // --border-style: dashed;
    }
    .buttonList {
      margin-top: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .buttonItem {
        width: 135px;
        margin-bottom: 12px;
      }
    }
  }
}
.inviteCopyCodeToast .adm-toast-wrap {
  width: 150px !important;
}