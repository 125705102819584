@import "~@config/styles.less";
.welcome-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .nav-bar {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;

    .back-button {
      width: 24px;
      height: 24px;
      padding: 10px;
      margin-left: 14px;
      background-color: white;
    }
  }
  .head-container {
    width: 100%;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .logo {
      height: 24px;
      margin-left: 24px;
    }
    .push-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .website {
        font-size: 12px;
        font-weight: 400;
        color: #1B1B1B;
        text-align: right;
      }
      .arrow {
        width: 16px;
        margin-left: 2px;
        margin-right: 16px;
      }
    }
  }
  .body-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin: 64px 32px 24px 32px;
      font-size: 22px;
      font-weight: 600;
      color: #1B1B1B;
      line-height: 28px;
      text-align: center;
    }
    .content {
      margin: 0 32px;
      font-size: 14px;
      font-weight: 400;
      color: #898989;
      line-height: 21px;
      text-align: center;
    }
    .button-container {
      display: flex;
      flex-direction: column;
      margin-top: 48px;

      .download-button {
        width: 135px;
        margin-bottom: 12px;
      }
    }
  }
}