.artworks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin: 4px 0;

  .artworks-item {
    width: calc(50vw - 24px);
    margin: 12px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .artworks-item-image-div {
      width: calc(50vw - 24px);
      height: calc(50vw - 24px);
      position: relative;

      .artworks-item-image {
        width: calc(50vw - 24px);
        height: calc(50vw - 24px);
        background-color: #f2f2f2;
        border-radius: 8px;
      }
      .artworks-item-shade {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 50%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        border-radius: 0px 0px 8px 8px;
      }
      .artworks-item-soldout {
        position: absolute;
        left: 8px;
        right: 8px;
        bottom: 8px;
        height: 15px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: white;
        text-align: center;
      }
    }
    .artworks-item-number {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #212121;
      margin-top: 12px;
    }
    .artworks-item-name {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #808080;
    }
    .artworks-item-price {
      margin-top: 12px;
      height: 24px;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: #212121;
      border: 1px solid #212121;
      border-radius: 24px;
      padding: 0 12px;
    }
    .artworks-item-soldout {
      border-color: #DEDEDE;
      color: #DEDEDE;
    }
  }
}
.collections {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin: 8px 0;

  .collections-item {
    width: 100%;
    margin: 8px 0;
    position: relative;

    .collections-item-image {
      width: 100%;
      height: 84px;
      border-radius: 8px;
      object-fit: cover;
    }
    .collections-item-container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.75);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .collections-item-icon {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        border: 2px solid rgba(255, 255, 255, 0.75);
        margin-left: 18px;
        object-fit: cover;
      }
      .collections-item-content {
        margin-left: 14px;

        .collections-item-name {
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          color: white;
        }
        .collections-item-number {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          color: rgba(255, 255, 255, 0.65);
          margin-top: 4px;
        }
      }
    }
  }
}
.foot {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 15px;
  color: #BFBFBF;
  margin: 32px 0;

  .foot-image {
    margin-left: 5px;
    height: 17px;
  }
}
.navbar {
  width: 100%;
  height: 44px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .back-button {
    width: 24px;
    margin-left: 16px;
  }
}