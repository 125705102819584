@import "~@config/styles.less";

.depositSubcribtionsPage {
  // width: 100%;
  width: calc(100% - 32px);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  .sectionContainer {
    margin: 20px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 22px;
      line-height: 26px;
      font-weight: 600;
      color: @color_1b1b1b;
    }
    .screen {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      .icon {
        width: 20px;
      }
      .content {
        font-size: 17px;
        font-weight: 600;
        line-height: 20px;
        color: @color_ff9602;
      }
      .adm-popup-body-position-bottom {
        width: calc(100% - 32px);
        bottom: 0;
        left: 16px;
        right: 16px;
        bottom: 15px;
        border-radius: 15px;
      }
      .adm-action-sheet-button-item-name {
        color: black;
        font-size: 17px;
        font-weight: 400;
      }
      .adm-action-sheet-button-item-wrapper {
        border-top: 1px solid @color_e6e6e6;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .adm-button-block {
        height: 50px;
      }
    }
  }
  .listData {
    // width: 100%;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;

    .dataItem {
      width: 100%;
      background-color: white;
      border-radius: 15px;
      box-shadow: 0px 2px 16px @color_e0e0e0;
      margin-bottom: 15px;
      padding: 0 16px;

      .headContainer {
        padding: 15px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .icon {
          width: 32px;
          height: 32px;
          border-radius: 16px;
        }
        .nameAndTime {
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          flex: 1;

          .name {
            color: black;
            font-size: 18px;
            line-height: 21px;
            font-weight: 400;
          }
          .time {
            color: @color_9fa0a0;
            font-size: 13px;
            line-height: 15px;
            font-weight: 400;
          }
        }
        .status {
          font-size: 15px;
          font-weight: 400;
          line-height: 21px;
        }
        .active {
          color: @color_ff1d03;
        }
        .completed {
          color: @color_0fb897;
          position: relative;
        }
        .completed::before {
          content: "";
          width: 13px;
          height: 13px;
          background: url("https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/deposit/completedIcon.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          display: inline-block;
          top: 50%;
          left: -14px;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }
        .redeemed {
          color: @color_9fa0a0;
        }
      }
      .adm-popup-body-position-bottom {
        width: calc(100% - 32px);
        bottom: 0;
        left: 16px;
        right: 16px;
        bottom: 15px;
        border-radius: 15px;
      }
      .adm-action-sheet-button-item-name {
        color: @color_ff7700;
        font-size: 17px;
        font-weight: 400;
      }
      .adm-action-sheet-button-item-wrapper {
        border-top: 1px solid @color_e6e6e6;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .adm-button-block {
        height: 50px;
      }
      .contentUl {
        padding: 10px 0;
        border-top: 1px solid @color_e6e6e6;
        margin-bottom: 4px;

        .contentLi {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin: 6px 0;

          .key {
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            color: @color_9fa0a0;
          }
          .value {
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            color: @color_1b1b1b;
          }
        }
      }
    }
  }
  .emptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 48px;
    width: 100%;

    .emptyImage {
      width: 96px;
    }
    .emptyDesc {
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      color: @color_9fa0a0;
      margin-top: 12px;
    }
  }
}
.redeemBodyContainer {
  width: 100% !important;
  left: 0 !important;
  bottom: 0 !important;
  background-color: white;
  border-radius: 15px 15px 0 0 !important;
}
.redeemPopup {
  // width: 100%;
  width: calc(100% - 32px);
  
  .container {
    // width: 100%;
    width: calc(100% - 32px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 24px;
    position: relative;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 24px;
      height: 24px;
    }
    .warnIcon {
      width: 48px;
      margin-top: 28px;
    }
    .title {
      margin-top: 16px;
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      color: @color_1b1b1b;
    }
    .content {
      margin-top: 12px;
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
      color: @color_9fa0a0;
      text-align: center;
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: @color_e6e6e6;
      margin-top: 20px;
    }
    .list {
      margin-top: 14px;
      width: 100%;

      .itemData {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 6px 0;

        .key {
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          color: @color_9fa0a0;
        }
        .value {
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          color: @color_1b1b1b;
        }
      }
      .itemData:last-child .value {
        color: @color_ff7700;
      }
    }
    .redeemButn {
      width: calc(100% - 60px);
      margin: 20px 30px 0 30px;
      height: 50px;
      --background-color: @color_ff1d03;
      --text-color: white;
      --border-radius: 25px;
      --border-style: none;
      font-size: 17px;
      font-weight: 400;
    }
    .cancelButn {
      color: @color_ff1d03;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      margin-top: 26px;
    }
  }
}
.refreshHead {
  width: calc(100vw - 32px);
}
.loadMoreButton {
  width: 100%;
  font-size: 14px;
  color: #999999;
  text-align: center;
}