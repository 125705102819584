@import "~@config/styles.less";

.listHeader {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 22px 20px;
  background-size: 100% 100%;
  border-radius: 15px;
  min-height: 120px;
  box-shadow: 0px 2px 24px 0px rgba(255, 119, 0, 0.15), 0px 2px 4px 0px rgba(255, 119, 0, 0.15);

  .head {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .txt {
        color: @color_ffd499;
        font-size: 15px;
        line-height: 18px;
        font-family: Regular;
        font-weight: 500;
      }
      .number {
        color: white;
        font-size: 34px;
        font-family: Regular;
        font-weight: 700;
        line-height: 41px;
        margin-top: 6px;
      }
    }
    .arrow {
      width: 36px;
    }
    .arrow:active {
      border-radius: 18px;
      background-color: @color_ffa633;
    }
  }
  .footUl {
    margin-top: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .itemLi {
      align-items: flex-start;
      justify-content: flex-start;

      .name {
        font-size: 15px;
        line-height: 18px;
        font-family: Regular;
        font-weight: 500;
        color: @color_ffd499;;
      }
      .content {
        font-size: 18px;
        line-height: 22px;
        color: white;
        font-family: Regular;
        font-weight: 600;
        margin-top: 6px;
      }
    }
  }
}