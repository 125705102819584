@import "~@config/styles.less";

.depositStakingPage2 {
  width: calc(100% - 32px);
  // width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  .sectionTitle {
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    color: @color_1b1b1b;
    margin: 20px 0;
  }
  .depositList {
    width: 100%;
    display: flex;
    flex-direction: column;

    .dataItem {
      width: 100%;
      background-color: white;
      border-radius: 16px;
      // box-shadow: 0px 2px 16px @color_e0e0e0;
      border: 1px solid #e6e6e6;
      margin-bottom: 16px;
      box-sizing: border-box;
    
      .headContainer {
        // width: 100%;
        width: calc(100% - 32px);
        background-color: transparent;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 16px;
        border-bottom: 1px solid #e6e6e6;
        position: relative;

        .extra-div {
          position: absolute;
          width: 132px;
          height: 55px;
          top: 0;
          right: 0;
          background-image: url("https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/deposit/extra.png");
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;

          .extra-div-item-1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-right: 15px;

            img {
              width: 12px;
              height: 12px;
              margin-right: 2px;
            }
            p {
              font-size: 11px;
              color: #fff;
              font-weight: 500;
            }
          }
          .extra-div-item-2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 3px;
            padding-right: 15px;

            img {
              width: 12px;
              height: 12px;
              margin-right: 2px;
            }
            p {
              font-size: 11px;
              color: rgba(255, 255, 255, 0.65);
              font-weight: 500;
            }
          }
        }
        .extra-div2 {
          width: 146px;
          background-image: url("https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/deposit/extra2.png");
        }
        .extra-div3 {
          position: absolute;
          width: 146px;
          height: 55px;
          top: 0;
          right: 0;
          background-image: url("https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/deposit/extra3.png");
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
        }
        .icon {
          width: 32px;
          height: 32px;
          border-radius: 16px;
        }
        .nameContainer {
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          flex: 1;

          .title {
            color: @color_1b1b1b;
            font-size: 18px;
            line-height: 21px;
            font-weight: 400;
            position: relative;
          }
          .fire::after {
            content: "🔥";
            color: @color_c6c7c7;
            font-size: 12px;
            line-height: 12px;
            position: absolute;
            display: inline-block;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            margin-left: 6px;
          }
          .available {
            color: @color_9fa0a0;
            font-size: 13px;
            line-height: 15px;
            font-weight: 400;
            margin-top: 2px;
          }
        }
        .dailyInterestContainer {
          margin-right: 12px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-start;

          .dayInterest {
            font-size: 18px;
            font-weight: 600;
            line-height: 21px;
            color: @color_0fb897;
          }
          .dayInterestDesc {
            font-size: 13px;
            font-weight: 400;
            line-height: 15px;
            color: @color_9fa0a0;
            margin-top: 2px;
          }
        }
        .block {
          display: flex;
        }
        .none {
          display: none;
        }
        .direction {
          width: 24px;
          transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -o-transform: rotate(180deg); 	
        }
        .upDirectionAnimation {
          transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          // animation-name: upDirectionKeyFrames;
          // animation-duration: 0.3s;
          // animation-timing-function: ease-in-out;
          // animation-iteration-count: 1;
        }
        @keyframes upDirectionKeyFrames {
          0% {
            transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -o-transform: rotate(180deg); 
          }
          100% {
            transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
          }
        }
        .downDirectionAnimation {
          transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          // animation-name: downDirectionKeyFrames;
          // animation-duration: 0.3s;
          // animation-timing-function: ease-in-out;
          // animation-iteration-count: 1;
        }
        @keyframes downDirectionKeyFrames {
          0% {
            transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg); 
          }
          100% {
            transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -o-transform: rotate(180deg);
          }
        }
      }
      .headContainer:active {
        background-color: @color_f8f8f8;
      }
      .bodyList {
        // width: 100%;
        width: calc(100% - 32px);
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        max-height: 0;
        overflow: hidden;

        .itemContainer {
          border-top: 1px solid @color_e6e6e6;
          padding: 20px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          &:first-child {
            border-top-style: none;
          }

          .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .interest {
              color: @color_0fb897;
              font-size: 24px;
              line-height: 29px;
              font-weight: 600;
              // position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;

              span {
                font-size: 11px;
                color: #808080;
                text-decoration-line: line-through;
                font-weight: 400;
                margin-left: 8px;
              }
            }
            .interest::after {
              // content: "Daily interest";
              content: "Est. APY";
              color: @color_c6c7c7;
              font-size: 12px;
              line-height: 12px;
              font-weight: 400;
              // position: absolute;
              display: inline-block;
              // top: 50%;
              // -webkit-transform: translateY(-50%);
              // -moz-transform: translateY(-50%);
              // -ms-transform: translateY(-50%);
              // transform: translateY(-50%);
              white-space: nowrap;
              margin-left: 8px;
            }
            .duration {
              color: @color_898989;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              margin-top: 6px;
            }
          }
          .subscribeBtn-div {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
          .subscribeBtn {
            width: 102px;
            height: 34px;
            --background-color: @color_ff1d03;
            --text-color: white;
            --border-radius: 17px;
            --border-style: none;
            font-size: 14px;
            font-weight: 400;
          }
          .left-warn {
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            color: @color_9fa0a0;
            margin-top: 5px;
          }
        }
      }
      .showBodyListAnimation {
        max-height: 100vh;
        // animation-name: showBodyListKeyFrames;
        // animation-duration: 0.3s;
        // animation-timing-function: ease-in-out;
        // animation-iteration-count: 1;
      }
      @keyframes showBodyListKeyFrames {
        0% {
          max-height: 0;
        }
        100% {
          max-height: 100vh;
        }
      }
      .hideBodyListAnimation {
        max-height: 0;
        // animation-name: hideBodyListKeyFrames;
        // animation-duration: 0.3s;
        // animation-timing-function: ease-in-out;
        // animation-iteration-count: 1;
      }
      @keyframes hideBodyListKeyFrames {
        0% {
          max-height: 100vh;
        }
        100% {
          max-height: 0;
        }
      }
    }
  }
  .refreshHead {
    width: calc(100vw - 32px);
  }
}
.subcribeBodyContainer {
  width: 100% !important;
  left: 0 !important;
  bottom: 0 !important;
  background-color: white;
  border-radius: 15px 15px 0 0 !important;
}
.subscribePopup {
  // width: 100%;
  width: calc(100% - 32px);

  .container {
    // width: 100%;
    width: calc(100% - 32px);
    padding: 16px;

    .iconAndName {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .icon {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        margin-left: 8px;
      }
      .name {
        flex: 1;
        margin-left: 12px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: @color_1b1b1b;
      }
      .close {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
    }
    .interestAndPeroid {
      width: calc(100% - 16px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 16px 8px 0 8px;

      .interestAndPeroidContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .key {
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          color: @color_9fa0a0;
        }
        .value {
          font-size: 24px;
          font-weight: 600;
          line-height: 24px;
          color: @color_1b1b1b;
          margin-top: 6px;
        }
      }
      .interestAndPeroidContainer:last-child {
        align-items: flex-end;
      }
    }
    .line {
      width: calc(100% - 16px);
      height: 1px;
      background-color: @color_e6e6e6;
      margin: 20px 8px 0 8px;
    }
    .stakingTitle {
      width: calc(100% - 16px);
      margin: 16px 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .name {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: @color_1b1b1b;
      }
      .available {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: @color_1b1b1b;
      }
      .available:before {
        content: "Available：";
        color: @color_9fa0a0;
        display: inline-block;
      }
    }
    .inputContainer {
      width: 100%;
      height: 50px;
      background-color: @color_f8f8f8;
      border-radius: 25px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .stakingInput {
        flex: 1;
        height: 50px;
        padding: 0 22px;
        --placeholder-color: @color_9fa0a0;
        --color: @color_1b1b1b;
      }
      .maxButn {
        margin-right: 22px;
        font-size: 17px;
        font-weight: 400;
        line-height: 20px;
        color: @color_ff7700;
      }
    }
    .total {
      margin: 16px 8px;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      color: @color_1b1b1b;
      text-align: right;

      &:before {
        content: "Total：";
        color: @color_9fa0a0;
        display: inline-block;
      }
    }
    .desc {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      color: @color_9fa0a0;
      margin: 20px 8px;
      text-align: center;

      .lightText {
        color: @color_ff7700;
      }
    }
    .subscribeButn {
      width: calc(100% - 76px);
      height: 50px;
      --background-color: @color_ff1d03;
      --text-color: white;
      --border-radius: 25px;
      --border-style: none;
      font-size: 17px;
      font-weight: 400;
      margin-left: 38px;
      margin-bottom: 20px;
    }
  }
}
.ok-button {
  font-size: 17px;
  color: @color_ff1d03;
}