@import "~@config/styles.less";

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999999;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loadText {
      color: white;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}