.artwork-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    width: calc(100vw - 32px);
    height: calc(100vw - 32px);
    margin: 8px 16px;
    border-radius: 8px;
  }
  .name {
    margin: 8px 16px;
    width: calc(100vw - 32px);
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    text-align: left;
  }
  .price-days {
    margin: 8px 16px;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .price-div {
      display: flex;
      flex-direction: column;

      .txt {
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        color: #808080;
      }
      .price {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-top: 2px;
        color: #212121;
      }
    }
    .days-div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 24px;
      padding: 0 12px;
      border-radius: 24px;
      background-color: rgba(81, 0, 237, 0.1);

      .icon {
        width: 8px;
      }
      .day {
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #5100ED;
        margin-left: 5px;
      }
    }
  }
  .amount-div {
    margin: 8px 16px;
    width: calc(100% - 32px);
    height: 67px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background: #E6E6E6;
      transform: scaleY(0.5);
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #E6E6E6;
      transform: scaleY(0.5);
    }
    .amount-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .txt {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #808080;
      }
      .number {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #212121;
        margin-top: 2px;
      }
    }
  }
  .creator-div {
    margin: 10px 16px;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .icon {
      width: 32px;
      height: 32px;
      border-radius: 32px;
    }
    .creator-container {
      margin-left: 8px;

      .txt {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #808080;
      }
      .txt-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #212121;
        margin-top: 2px;
      }
    }
  }
  .collection-div {
    margin-top: 30px;
    width: 100%;

    .txt {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #808080;
      margin-left: 16px;
    }
    .head-container {
      width: calc(100% - 32px);
      margin: 0 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .txt-name {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        color: #212121;
      }
      .txt-button {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #5100ED;
      }
    }
    .body-container {
      width: 100%;
      margin-top: 24px;
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
      .bodyItem {
        display: inline-block;
        width: 148px;
        margin-left: 16px;
        margin-right: 0;

        &:last-child {
          margin-right: 16px;
        }
        .item-image {
          width: 100%;
          height: 148px;
          border-radius: 8px;
        }
        .item-name {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #212121;
          margin-top: 12px;
        }
      }
    }
  }
  .mint-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 64px;
    z-index: 999;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background: #E6E6E6;
      transform: scaleY(0.5);
    }
    .mint-button {
      width: calc(100% - 32px);
      height: 48px;
      border-radius: 48px;
      background-color: #212121;
      color: white;
      font-weight: 600;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .soldout {
      background-color: #DEDEDE;
      color: white;
    }
  }
}
.artwork-popup-body-class {
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .close-button {
    width: 24px;
    margin: 18px 16px;
  }
  .title {
    margin: 8px 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #212121;
  }
  .select-div {
    margin: 16px;
    margin-bottom: 0;
    background-color: white;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    width: calc(100% - 32px);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 0;

    .select-icon {
      width: 24px;
      margin-left: 16px;
    }
    .select-txt {
      flex: 1;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #212121;
      margin: 0 8px;
      margin-top: 2px;

      span {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #808080;
        margin-top: 6px;
        white-space: nowrap;
      }
    }
    .insufficient {
      color: #808080;
    }
    .avaible {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #808080;
      margin-right: 16px;
      text-align: right;
      margin-top: 4px;

      span {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #E01D0B;
        margin-top: 1px;
      }
    }
  }
  .selected {
    background-color: #F2F2F2;
    border: 2px solid #212121;
  }
  .confirm-button {
    margin: 32px 16px 8px 16px;
    width: calc(100% - 32px);
    height: 48px;
    border-radius: 48px;
    background-color: #212121;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    color: white;
  }
  .insufficient-button {
    background-color: #DEDEDE;
  }
}
.adm-center-popup-wrap {
  max-width: 100vw;
}
.dialog-body-class {
  width: calc(100vw - 64px);

  .adm-auto-center-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #808080;
  }
  .adm-button-primary {
    color: #5100ED;
    font-weight: 400;
    font-size: 16px !important;
  }
}