@import "~@config/styles.less";

.privatePage {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(29deg, @color_ff1d03 0%, @color_ff9602 100%);
  
  .headImage {
    width: 100%;
  }
  .bodyContainer {
    margin: -40px 15px 0 15px;
    padding: 40px 18px 16px 18px;
    width: calc(100% - 66px);
    border-radius: 15px;
    background-color: white;
    position: relative;

    .logoIcon {
      position: absolute;
      width: 140px;
      top: -32px;
      left: 50%;
      transform: translate(-50%, 0); 
    }
    .dataList {
      width: 100%;
      border-bottom: 1px solid @color_eff2f2;

      .listItem {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        .key {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: @color_7f8080;
        }
        .value {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: @color_262626;
        }
      }
      .listItem:nth-child(2) {
        margin-bottom: 24px;
      }
      .listItem:last-child {
        margin-bottom: 20px;
      }
    }
    .contentDesc {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: @color_7f8080;
      margin-top: 28px;
      text-align: center;

      .lightText {
        display: inline-block;
        color: @color_262626;
        font-weight: bold;
      }
    }
    .accountInputContainer {
      width: 100%;
      margin-top: 24px;
      position: relative;

      .accountInputDiv {
        width: calc(100% - 30px);
        height: 64px;
        border-radius: 32px;
        padding: 0 15px;
        font-size: 34px;
        font-weight: 600;
        color: @color_ff7700;
        caret-color: @color_ff7700;
        text-align: center;
      }
      .accountInputDiv::-webkit-input-placeholder {
        position: relative;
        top: -8px;
        padding-top: 10px !important;
      }
      .accountInputDiv:-moz-placeholder {
        position: relative;
        top: -8px;
        padding-top: 10px !important;
      }
      .accountInputDiv:-ms-input-placeholder {
        position: relative;
        top: -8px;
        padding-top: 10px !important;
      }
      .inputUnit {
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translate(0, -50%); 
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: @color_ff7700;
      }
      .diasbleInputUnit {
        color: @color_7f8080;
      }
    }
    .usdtCount {
      font-size: 16px;
      font-weight: 400;
      color: @color_262626;
      line-height: 24px;
      margin-top: 16px;
      text-align: center;
    }
    .editContainer {
      width: 100%;
      margin: 16px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .submitBtn {
        width: calc(100% - 44px);
        height: 50px;
        --background-color: @color_ff7700;
        --text-color: white;
        --border-radius: 25px;
        --border-style: none;
        font-size: 18px;
        font-weight: 500;
        margin: 0 22px;
        box-shadow: 0px 3px 6px rgba(255, 171, 97, 0.5);
      }
      .editSubmitBtn {
        width: 60%;
        margin: 0;
      }
      .cancelBtn {
        display: block;
        width: 40%;
        height: 50px;
        --background-color: white;
        --text-color: @color_ff7700;
        --border-style: none;
        font-size: 18px;
        font-weight: 500;
      }
      .noEditCancelBtn {
        display: none;
      }
    }
    .congratulateContainer {
      width: 100%;
      margin-top: 24px;

      .title {
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        color: @color_10c29f;
        text-align: center;
      }
      .content {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: @color_10c29f;
        text-align: center;

        .lightText {
          color: @color_262626;
          font-weight: bold;
        }
      }
      .editContainer {
        margin-top: 27px;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

        .editIcon {
          width: 24px;
        }
        .editTxt {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: @color_ff7700;
          margin-left: 5px;
        }
      }
    }
  }
  .footContainer {
    margin: 16px 15px 50px 15px;
    padding: 26px 18px;
    width: calc(100% - 66px);
    border-radius: 15px;
    background-color: white;

    .applyWarn {
      font-size: 16px;
      font-weight: 600;
      color: @color_ff7700;
      line-height: 22px;
      text-align: center;
    }
  }
  .maskContainer {   

    .contentDiv {
      width: calc(100% - 64px);
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 15px;

      .toolContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .closeButn {
          width: 24px;
        }
      }
      .applyTitle {
        font-size: 20px;
        font-weight: 600;
        color: @color_1b1b1b;
        line-height: 30px;
        text-align: center;
        margin-top: 8px;
      }
      .applyContent {
        font-size: 14px;
        font-weight: 600;
        color: @color_7f8080;
        line-height: 20px;
        margin-top: 16px;
        text-align: center;
      }
      .maskInputDiv {
        width: 100%;
        height: 50px;
        border-radius: 25px;
        padding: 0 6px;
        margin-top: 24px;
        font-size: 16px;
        color: @color_1b1b1b;
        text-align: center;
      }
      .confirmBtn {
        width: calc(100% - 70px);
        height: 50px;
        --background-color: @color_ff7700;
        --text-color: white;
        --border-radius: 25px;
        --border-style: none;
        font-size: 18px;
        font-weight: 500;
        margin: 16px 35px;
      }
    }
  }
  input {
    border: 1px solid @color_d6d9d9;
    outline-style: none;
    outline: none !important;
    -webkit-appearance: none !important; 
	  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  }
  input:focus, input:focus-visible, input:focus-within {
    border: 2px solid @color_ff7700;
  }
  input:disabled{
    background-color: @color_f3f3f3;
    color: @color_262626 !important;
    border-style: none;
  } 
  input::-webkit-input-placeholder {
    font-size: 16px;
    color: @color_a6a6a6;
    line-height: normal;
  }
  input:-moz-placeholder {
    font-size: 16px;
    color: @color_a6a6a6;
    line-height: normal;
  }
  input:-ms-input-placeholder {
    font-size: 16px;
    color: @color_a6a6a6;
    line-height: normal;
  }
}