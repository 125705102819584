.explore-page {
  width: 100%;

  .refreshHead {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: white;
    padding-bottom: 50px;
    box-sizing: border-box;
  }
  .title {
    font-size: 24px;
    color: #212121;
    font-weight: 600;
    margin-top: 16px;
    padding: 0 16px;
    box-sizing: border-box;
  }
  .banner {
    width: calc(100% - 32px);
    margin: 0 16px;
    margin-top: 24px;
    border-radius: 16px;
  }
  .session-title {
    font-size: 18px;
    color: #212121;
    font-weight: 600;
    margin-top: 24px;
    padding: 0 16px;
    box-sizing: border-box;
  }
  .session-list {
    width: 100%;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .session-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      position: relative;
      padding: 0 16px;
      box-sizing: border-box;

      .item-icon {
        width: 64px;
        height: 64px;
        object-fit: cover;
        border-radius: 16px;
      }
      .item-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 3px;

        .item-name-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;

          .item-name {
            font-size: 16px;
            color: #212121;
            font-weight: 600;
          }
          .item-icon {
            width: 16px;
            height: 16px;
          }
        }
        .item-desc {
          font-size: 12px;
          color: #808080;
        }
      }
      .item-sticky {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 16px;
        top: 10px;
      }
    }
    .item-pin {
      font-size: 16px;
      color: #ffffff;
      font-weight: 600;
    }
  }
}