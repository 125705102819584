@import "~@config/styles.less";
.set-username-page{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .nav-bar {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;

    .back-button {
      width: 24px;
      height: 24px;
      padding: 10px;
      margin-left: 14px;
      background-color: white;
    }
  }
  .title {
    width: calc(100% - 48px);
    font-size: 32px;
    font-weight: 600;
    color: #1B1B1B;
    text-align: left;
    margin: 8px 24px;
  }
  .content {
    width: calc(100% - 48px);
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #9FA0A0;
    text-align: left;
    margin: 8px 24px;
  }
  .input-container {
    margin: 24px;
    margin-bottom: 0;
    width: calc(100% - 48px);
    height: 50px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;

    .sign {
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      color: #1B1B1B;
      margin-right: 10px;
    }
    .input-div {
      flex: 1;
      --font-size: 16px;
      --color: #1B1B1B;
      
      .adm-input-element {
        line-height: 16px;
      }
    }
  }
  .input-container-active {
    border-color: #FF7801;
  }
  .done-button {
    margin-top: 40px;
    width: calc(100% - 188px);
    height: 50px;
    border-radius: 25px;
    background-color: #FF7700;
    box-shadow: none;
    font-size: 16px;
    font-weight: 400;
    color: white;

    &::before {
      border-radius: 25px;
    }
  }
  .skip-button {
    font-size: 15px;
    font-weight: 400;
    color: #FF7801;
    margin-top: 24px;
  }
}