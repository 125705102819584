@import "~@config/styles.less";

.freechargePage {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .bgImage {
    width: 100%;
  }
  .supportButn {
    position: absolute;
    top: calc(100vw / 375 * 1201);
    left: 0;
    right: 0;
    height: calc(100vw / 375 * 64);
    background-color: transparent;
  }
  .infoButn {
    position: absolute;
    top: calc(100vw / 375 * 1281);
    left: 0;
    right: 0;
    height: calc(100vw / 375 * 64);
    background-color: transparent;
  }
  .swapButnContainer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 74px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    .swapButn {
      width: calc(100% - 110px);
      height: 50px;
      background-color: #FF7700;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}