@import "~@config/styles.less";

.paymentMask {   

  .contentDiv {
    width: calc(100% - 64px);
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;

    .toolContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .closeButn {
        width: 24px;
      }
    }
    .applyTitle {
      font-size: 20px;
      font-weight: 600;
      color: @color_1b1b1b;
      line-height: 30px;
      text-align: center;
      margin-top: 8px;
    }
    .maskInput {
      background-color: @color_f8f8f8;
      // width: 100%;
      width: calc(100% - 32px);
      margin-top: 24px;
      height: 50px;
      font-size: 16px;
      border-radius: 25px;
      padding: 0 22px;
      --placeholder-color: @color_9fa0a0;
      --color: @color_1b1b1b;
      text-align: center;
    }
    .confirmBtn {
      width: calc(100% - 70px);
      height: 50px;
      --background-color: @color_ff7700;
      --text-color: white;
      --border-radius: 25px;
      --border-style: none;
      font-size: 18px;
      font-weight: 500;
      margin: 16px 35px;
    }
  }
}