@import "~@config/styles.less";
.be-global-partner-page {
  width: 100%;
  position: relative;

  .bg-image {
    width: 100%;
  }
  .rules-button {
    position: fixed;
    top: 8px;
    right: 0;
    font-size: 12px;
    line-height: 30px;
    color: white;
    padding: 0 16px;
    background-color: rgba(0, 0, 0, 0.3);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .form-container {
    position: absolute;
    top: calc(100vw / 375 * 2828);
    left: calc(100vw / 375 * 157);
    right: calc(100vw / 375 * 20);
    height: calc(100vw / 375 * 304);
    overflow: hidden;
    
    .form-image-scroll {
      height: 100%;
      overflow-x: auto;
      overflow-y: hidden;

      .form-image {
        height: 100%;
      }
    }
  }
  .button-container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 66px;
    background-color: white;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .invite-button-container {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;

      .invite-icon {
        width: 24px;
      }
      .invite-text {
        font-size: 12px;
        font-weight: 400;
        color: #1B1B1B;
        line-height: 12px;
        margin-top: 6px;
      }
    }
    .button {
      flex: 1;
      margin: 0 16px;
      height: 50px;
      border-radius: 25px;
      background-color: #FF7700;
      box-shadow: 0px 3px 6px 1px rgba(255, 171, 97, 0.5);
      font-size: 16px;
      font-weight: 400;
      color: white;
      border-style: none;

      &::before {
        border-radius: 25px;
      }
    }
  }
}