@import "~@config/styles.less";
.be-global-partner-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/premium/bg.png");
  height: 100%;
  background-repeat: no-repeat, no-repeat;

  .title{
    text-align: center;
    width: 100%;
    height: 29px;
    font-size: 24px;
    font-family: SF Pro Display-Semibold, SF Pro Display;
    font-weight: 600;
    color: #1B1B1B;
    line-height: 24px;
    -webkit-background-clip: text;
    margin-bottom: 20px;
    white-space:nowrap ;
  }

  .iconDagou{
    width: 20px;
    height: 20px;
  }
  .activeText{
    height: 19px;
    font-size: 16px;
    font-family: SF Pro Text-Regular, SF Pro Text;
    font-weight: 400;
    color: #FF7700;
    line-height: 24px;
    -webkit-background-clip: text;
  }




  .activeSubText{
    justify-content: center;
    align-items: center;
    height: 14px;
    font-size: 12px;
    font-family: SF Pro Text-Regular, SF Pro Text;
    font-weight: 400;
    color: #9FA0A0;
    line-height: 24px;
    -webkit-background-clip: text;
  }
  .activeRow{
    display: flex;
    flex-direction: row;
    margin: 0px;
  }

  .subTitle{
    height: 21px;
    font-size: 18px;
    font-family: SF Pro Text-Semibold, SF Pro Text;
    font-weight: 600;
    color: #1B1B1B;
    line-height: 22px;
    -webkit-background-clip: text;
    margin-bottom: 30px;
    white-space:nowrap ;
  }

  .row{
    display: flex;
    flex-direction: row;
    height: 60px;
  }
  .column{
    display: flex;
    flex-direction: column;
  }

  .rowTitle{
    width: 263px;
    height: 19px;
    font-size: 16px;
    font-family: SF Pro Text-Regular, SF Pro Text;
    font-weight: 400;
    color: #1B1B1B;
    line-height: 22px;
    -webkit-background-clip: text;
  }
  .rowSubTitle{
    width: 263px;
    height: 14px;
    font-size: 12px;
    font-family: SF Pro Text-Regular, SF Pro Text;
    font-weight: 400;
    color: #9FA0A0;
    line-height: 18px;
    -webkit-background-clip: text;
  }


  .icon{
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  .bg-image {
    width: 100%;
  }

  .container{
    background: #FFFFFF;
    border-radius: 16px 16px 16px 16px;
    opacity: 1;
    padding: 20px 20px 0px 16px;
    margin: 20px 20px 0px 20px;
  }

  .heighBottom{
    height: 130px;
  }
  .buttonBottom{
    height: 34px;
  }

  .rules-button {
    position: fixed;
    top: 8px;
    right: 0;
    font-size: 12px;
    line-height: 30px;
    color: white;
    padding: 0 16px;
    background-color: rgba(0, 0, 0, 0.3);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .form-container {
    position: absolute;
    top: calc(100vw / 375 * 2828);
    left: calc(100vw / 375 * 157);
    right: calc(100vw / 375 * 20);
    height: calc(100vw / 375 * 304);
    overflow: hidden;
    
    .form-image-scroll {
      height: 100%;
      overflow-x: auto;
      overflow-y: hidden;

      .form-image {
        height: 100%;
      }
    }
  }
  .button-container {
    left: 0;
    right: 0;
    bottom: 0;
    height: 115px;
    background-color: white;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .invite-button-container {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;

      .invite-icon {
        width: 24px;
      }
      .invite-text {
        font-size: 12px;
        font-weight: 400;
        color: #1B1B1B;
        line-height: 12px;
        margin-top: 6px;
      }
    }
    .button {
      flex: none;
      margin: 10px 0px 0px 0px;
      height: 50px;
      width: 90%;
      border-radius: 25px;
      background-color: #FF7700;
      box-shadow: 0px 3px 6px 1px rgba(255, 171, 97, 0.5);
      font-size: 16px;
      font-weight: 400;
      color: white;
      border-style: none;

      &::before {
        border-radius: 25px;
      }
    }
  }
  .active{
    justify-content: center;
    align-items: center;
    height: 10px;
    height: 100px;
  }

  .activiteTips{
    height: 29px;
    font-size: 12px;
    font-family: SF Pro Text-Regular, SF Pro Text;
    font-weight: 400;
    color: #9FA0A0;
    line-height: 15px;
    -webkit-background-clip: text;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .activiteSubTips{
    font-weight: bold;
    color: #1b1b1b;
  }
}