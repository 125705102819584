@import "~@config/styles.less";

.rulePage {
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  padding: 24px;

  .title {
    font-size: 30px;
    font-weight: 400;
    line-height: 30px;
    color: @color_1b1b1b;
  }
  .line {
    margin-top: 16px;
    width: 100%;
    height: 1px;
    background-color: @color_eff2f2;
  }
  .contentList {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .contentItem {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 16px 0;

      .tag {
        width: 4px;
        height: 4px;
        min-width: 4px;
        min-height: 4px;
        border-radius: 2px;
        background-color: @color_ff1d03;
        margin-top: 10px;
        margin-right: 10px;
      }
      .content {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: @color_898989;

        .lightText {
          display: inline-block;
          color: @color_ff1d03;
        }
      }
    }
  }
}