.task-page {
  width: 100%;
  display: flex;
  flex-direction: column;

  .head {
    width: 100%;
    height: 230px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: -32px;

    .navbar {
      width: 100%;
      height: 44px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;

      img {
        width: 24px;
        height: 24px;
        padding: 10px 16px;
      }
    }
    .amount {
      font-size: 48px;
      font-weight: 600;
      color: white;
      margin-top: 20px;
      font-family: Regular;
    }
    .txt {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 7px;

      img {
        width: 16px;
        height: 16px;
        margin: 0 6px;
      }
      p {
        font-size: 18px;
        color: white;
        // font-family: Regular;
        font-weight: 600;
      }
    }
    .lock {
      height: 31px;
    }
    .prerequisite {
      margin-top: 9px;
      // font-family: Regular;
      font-size: 14px;
      color: white;
    }
    .upgrade {
      margin: 20px 16px;
      width: calc(100% - 32px);
      height: 48px;
      background-color: rgba(255, 255, 255, 0.30);
      border-radius: 25px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      p {
        font-size: 16px;
        color: white;
        font-weight: 600;
        // font-family: Regular;
      }
      img {
        width: 20px;
        height: 20px;
        margin-left: 4px;
      }
    }
    .t1 {
      font-size: 36px;
      font-weight: 600;
      // font-family: Regular;
      color: white;
      margin-top: 32px;
    }
    .t2 {
      font-size: 14px;
      // font-family: Regular;
      color: white;
      margin-top: 2px;
    }
  }
}
.session {
  width: 100%;
  padding: 32px 16px;
  padding-bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background-color: white;

  .title {
    font-size: 18px;
    color: #212121;
    font-weight: 600;
    // font-family: Regular;
    width: 100%;
  }
  .list {
    width: 100vw;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory; 
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 16px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-bottom: 40px;

    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      width: 72px;
      height: 100px;
      margin-right: 8px;
      border-radius: 8px;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:last-child {
        margin-right: 0;
      }

      .day {
        font-size: 12px;
        // font-family: Regular;
      }
      img {
        width: 24px;
        height: 24px;
        margin: 8px 0;
      }
      .amount {
        font-size: 16px;
        font-weight: 600;
        // font-family: Regular;
      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #E6E6E6;
  }
  .list2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 16px;

    .item2 {
      width: 100%;
      margin: 12px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .t1 {
        width: 32px;
        height: 40px;
        background-color: #F7F7F7;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
      }
      .t2 {
        flex: 1;
        margin-left: 8px;
        margin-right: 14px;
        font-size: 14px;
        // font-family: Regular;
        color: #212121;
        font-weight: 600;
        word-break: break-word;
      }
      .amount-button {
        width: 90px;
        height: 32px;
        border-radius: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        p {
          font-size: 14px;
          color: white;
          font-weight: 600;
          // font-family: Regular;
        }
      }
    }
  }
}