.reward-page {
  width: 100%;

  .tab-container {
    margin-top: 10px;
    --title-font-size: 14px;

    .adm-tabs-tab {
      color: #9FA0A0;
    }
    .adm-tabs-tab-active {
      color: #1B1B1B;
    }
    .adm-tabs-tab-line {
      background: #FF7801;
    }
    .adm-tabs-tab-wrapper-stretch {
      flex: none;
    }
  }
  .menu-list {
    width: calc(100% - 48px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 16px 16px 24px 16px;

    .menu-item {
      padding: 9px 12px;
      border-radius: 32px;
      font-size: 12px;
      font-weight: 400;
      color: #9FA0A0;
      border: 1px solid #E5E5E5;
      margin-right: 6px;
      margin-bottom: 10px;
    }
    .selected {
      border-color: #FF7700;
      background-color: #FF78011A;
      color: #FF7700;
    }
  }
  .number {
    font-size: 32px;
    font-weight: 600;
    color: #1B1B1B;
    margin: 0 16px;
    margin-top: -10px;

    .symble {
      font-size: 12px;
    }
  }
  .line {
    width: calc(100% - 32px);
    margin: 24px 16px 11px 16px;
    height: 1px;
    background-color: #eeeeee;
  }
  .data-list {
    width: 100%;
    margin: 0 16px;
    display: flex;
    flex-direction: column;


    .data-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .icon {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }
      .content-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
        flex: 1;
        padding: 20px 0;
  
        .type-time {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
  
          .type {
            font-size: 12px;
            color: #1B1B1B;
          }
          .time {
            font-size: 12px;
            color: #9FA0A0;
            margin-top: 6px;
          }
        }
        .record {
          font-size: 16px;
          font-weight: 500;
          color: #1B1B1B;
        }
      }
    }
  }
}
.customise-popup-container {
  a {
    color: #FF7700;
  }
  .adm-calendar-cell.adm-calendar-cell-selected.adm-calendar-cell.adm-calendar-cell-selected {
    background: #FF7700;
  }
  .customise-container {
    width: 100%;
    height: 90vh;
    background-color: rgba(0, 0, 0, 0.5);

    .container {
      width: 100%;
      height: 100%;
      background-color: white;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .close-button {
        width: 24px;
        margin: 16px;
      }
      .confirm-button {
        margin: 0 auto;
        margin-top: 40px;
        width: calc(100% - 108px);
        height: 50px;
        border-radius: 25px;
        background-color: #FF7700;
        box-shadow: none;
        font-size: 16px;
        font-weight: 400;
        color: white;

        &::before {
          border-radius: 25px;
        }
      }
    }
  }
}
.refreshHead {
  width: calc(100vw - 32px);
}
.loadMoreButton {
  width: 100%;
  font-size: 14px;
  color: #999999;
  text-align: center;
  margin: 20px 0;
}