@import "~@config/styles.less";

.rewardsPage {
  width: calc(100% - 32px);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  .listUl {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .itemData {
      width: calc(100% - 32px);
      background-color: white;
      border-radius: 16px;
      box-shadow: 0px 2px 16px @color_e0e0e0;
      margin-bottom: 16px;
      padding: 4px 16px;

      .container {
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .key {
          font-size: 15px;
          font-weight: 400;
          line-height: 15px;
          color: @color_9fa0a0;
        }
        .status {
          font-size: 15px;
          font-weight: 400;
          line-height: 21px;
          color: @color_1b1b1b;
        }
        .total {
          font-size: 17px;
          font-weight: 500;
          line-height: 18px;
          color: @color_1b1b1b;
          vertical-align: middle;
        }
        .total::after {
          content: "";
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: url("https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/rewards/fctLogo.png") no-repeat;
          background-size: 100% 100%;
          display: inline-block;
          vertical-align: bottom;
          margin-left: 8px;
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background-color: @color_e6e6e6;
      }
    }
  }
  .emptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 48px;
    width: 100%;

    .emptyImage {
      width: 96px;
    }
    .emptyDesc {
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      color: @color_9fa0a0;
      margin-top: 12px;
    }
  }
}
.loadMoreButton {
  width: 100%;
  font-size: 14px;
  color: #999999;
  text-align: center;
}