.creator-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image-name {
    margin: 8px 16px;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .icon {
      width: 64px;
      height: 64px;
      border-radius: 64px;
      object-fit: cover;
    }
    .name {
      margin-left: 12px;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: #212121;
    }
  }
  .tabs {
    width: 100%;
    margin-top: 8px;
    --title-font-size: 16px;
    --active-title-color: #212121;
    --active-line-color: #212121;

    .tab {
      color: #808080;
      flex: none;
      padding: 0 16px;
      font-weight: 600;
    }
    .adm-tabs-content {
      padding: 0 16px;
    }
  }
}