@import "~@config/styles.less";
.invitation-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    width: calc(100% - 32px);
    font-size: 32px;
    font-weight: 600;
    color: #1B1B1B;
    text-align: left;
    margin: 8px 24px;
  }
  .menu-list {
    width: calc(100% - 32px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 8px 16px 24px 16px;

    .menu-item {
      padding: 9px 12px;
      border-radius: 32px;
      font-size: 12px;
      font-weight: 400;
      color: #9FA0A0;
      border: 1px solid #E5E5E5;
      margin-right: 6px;
    }
    .selected {
      border-color: #FF7700;
      background-color: #FF78011A;
      color: #FF7700;
    }
  }
  .data-head {
    width: calc(100% - 32px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 12px;
    border-bottom: 1px solid #E5E5E5;

    .data-head-name {
      width: 30%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      text-align: right;
      color: #9FA0A0;

      .select-icon {
        width: 14px;
        min-width: 14px;
        min-height: 14px;
        height: 14px;
        margin-left: 5px;
      }
    }
    .user {
      width: auto;
      flex: 1;
    }
  }
  .data-list {
    width: calc(100% - 32px);

    .data-item {
      width: 100%;
      margin: 10px 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      .user-container {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;

        .grade-icon {
          width: 16px;
        }
        .grade-txt {
          font-size: 12px;
          color: #C5C5C5;
          font-weight: 400;
        }
        .username {
          margin-left: 6px;
          font-size: 12px;
          font-weight: 400;
          color: #1B1B1B;
        }
      }
      .assessment {
        width: 30%;
        font-size: 12px;
        font-weight: 500;
        color: #1B1B1B;
        margin: 0 10px;
        text-align: right;
        word-wrap: break-word;
      }
      .no-assessment {
        color: #C5C5C5;
      }
      .date {
        width: 30%;
        font-size: 12px;
        font-weight: 400;
        color: #9FA0A0;
        text-align: right;
      }
    }
  }
  .no-data {
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      width: 64px;
      margin-top: 60px;
    }
    .desc {
      font-size: 14px;
      font-weight: 400;
      color: #9FA0A0;
      margin-top: 20px;
    }
    .invite-button {
      margin-top: 32px;
      width: calc(100% - 60px);
      height: 50px;
      border-radius: 25px;
      background-color: #FF7700;
      box-shadow: none;
      font-size: 16px;
      font-weight: 400;
      color: white;
  
      &::before {
        border-radius: 25px;
      }
    }
  }
  .referer-container {
    width: calc(100% - 32px);
    height: 50px;
    position: fixed;
    left: 16px;
    bottom: 27px;
    background-color: white;
    box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    z-index: 99;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .text {
      font-size: 16px;
      font-weight: 500;
      color: #1B1B1B;
      margin-left: 16px;
    }
    .icon {
      width: 24px;
      margin-right: 12px;
    }
  }
  .mask-container {
    .content-container {
      width: calc(100% - 64px);
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .text {
        font-size: 14px;
        font-weight: 400;
        color: #898989;
        line-height: 21px;
        margin: 24px;
        text-align: center;

        .bold {
          font-weight: bold;
          color: #1B1B1B;
          display: inline-block;
        }
      }
      .confirm-button {
        width: 100%;
        height: 50px;
        --background-color: transparent;
        --text-color: #FF7700;
        --border-radius: 0;
        --border-style: none;
        border-style: none;
        border-top: 1px solid #E5E5E5;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
.invitation-popup-container {
  .add-referer-container {
    width: 100%;
    height: 90vh;
    background-color: rgba(0, 0, 0, 0.5);

    .container {
      width: 100%;
      height: 100%;
      background-color: white;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .close-button {
        width: 24px;
        margin: 16px;
      }
      .name {
        font-size: 32px;
        font-weight: 600;
        color: #1B1B1B;
        margin: 16px;
      }
      .input-name {
        font-size: 14px;
        font-weight: 400;
        color: #1B1B1B;
        margin: 16px;
        margin-bottom: 6px;
      }
      .input-container {
        margin: 0 16px;
        width: calc(100% - 32px);
        height: 50px;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0 16px;
    
        .input-div {
          flex: 1;
          --font-size: 16px;
          --color: #1B1B1B;
          
          .adm-input-element {
            line-height: 16px;
          }
        }
      }
      .input-container-active {
        border-color: #FF7801;
      }
      .confirm-button {
        margin: 0 auto;
        margin-top: 40px;
        width: calc(100% - 108px);
        height: 50px;
        border-radius: 25px;
        background-color: #FF7700;
        box-shadow: none;
        font-size: 16px;
        font-weight: 400;
        color: white;

        &::before {
          border-radius: 25px;
        }
      }
    }
  }
}