.result-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    width: 80px;
    margin-top: 40px;
  }
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #212121;
    margin-top: 16px;
  }
  .desc {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #808080;
    text-align: center;
  }
  .back-butn {
    width: 230px;
    height: 48px;
    border-radius: 48px;
    background-color: #212121;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    color: white;
    margin-top: 40px;
  }
  .view-butn {
    width: 230px;
    height: 48px;
    border-radius: 48px;
    border: 1px solid #212121;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    color: #212121;
    margin-top: 16px;
  }
}