@import "~@config/styles.less";
.rekall-page {
  width: 100%;

  .bottom {
    flex: 0;
    border-top: solid 1px var(--adm-color-border);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}