@import "~@config/styles.less";
.join-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .bg-image {
    width: 100%;
  }
  .head-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 56px;
    border-bottom: 1px solid white;
    z-index: 99;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .logo {
      height: 24px;
      margin-left: 24px;
    }
    .push-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .website {
        font-size: 12px;
        font-weight: 400;
        color: #1B1B1B;
        text-align: right;
      }
      .arrow {
        width: 16px;
        margin-left: 2px;
        margin-right: 16px;
      }
    }
  }
  .body-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin: -100px 32px 24px 32px;
      font-size: 28px;
      font-weight: 600;
      color: #1B1B1B;
      line-height: 35px;
      text-align: center;
    }
    .content {
      margin: 0 32px;
      font-size: 14px;
      font-weight: 400;
      color: #898989;
      line-height: 21px;
      text-align: center;

      .bold {
        color: #1B1B1B;
        font-weight: 600;
      }
    }
    .button {
      width: calc(100% - 188px);
      height: 50px;
      margin-top: 48px;
      border-radius: 25px;
      background-color: #FF7700;
      box-shadow: none;
      font-size: 16px;
      font-weight: 400;
      color: white;

      &::before {
        border-radius: 25px;
      }
    }
  }
}