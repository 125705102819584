@import "~@config/styles.less";
.rekall-home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100vh;
  background-color: white;

  .bg {
    width: calc(100% - 112px);
    margin-top: 120px;
  }
  .text {
    margin-top: 24px;
    font-size: 18px;
    font-weight: 600;
    color: #1B1B1B;
    line-height: 27px;
    text-align: center;
  }
}