.collection-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .head-div {
    width: 100%;
    position: relative;

    .image {
      width: 100%;
      height: 188px;
      object-fit: cover;
    }
    .head-container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.75);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .head-icon-number {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .icon {
          width: 64px;
          height: 64px;
          border: 4px solid rgba(255, 255, 255, 0.75);
          border-radius: 8px;
          margin: 0 20px;
          object-fit: cover;
        }
        .number {
          flex: 1;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: white;
          text-align: center;

          span {
            display: block;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: rgba(255, 255, 255, 0.65);
            margin-top: 2px;
          }
        }
      }
      .name {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        color: white;
        margin-top: 24px;
        margin-left: 16px;
      }
    }
  }
  .desc {
    margin: 24px 16px;
    margin-bottom: 0;
    width: calc(100% - 32px);
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #808080; 
    
    span {
      display: none;
    }
  }
  .desc-after {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    position: relative;

    &:after{
      content: "...";
      position: absolute;
      bottom: 0;
      right: 0;
      padding-right: 80px;
      padding-left: 4px;
      background: linear-gradient(to right, transparent, #fff 0%);
    }
    span {
      display: inline-block;
      position: absolute;
      bottom: 0;
      right: 0;
      color: #212121;
      font-weight: 600;
      z-index: 9;  
    }
  }
  .image-name {
    margin: 16px;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .icon {
      width: 24px;
      border-radius: 24px;
      object-fit: cover;
    }
    .name {
      margin-left: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #212121;
    }
  }
  .data-list {
    margin: 8px 16px;
    width: calc(100% - 32px);
  }
}